import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";
import { TemplateFormMap } from "infrastructure/routes/routes";
import { useMemo } from "react";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { Button } from "@mui/material";

interface SidebarProps {
	drawerWidth: number;
}

export const Sidebar = ({ drawerWidth }: SidebarProps) => {
	const links = useMemo(() => Object.entries(TemplateFormMap).map(([key, value]) => ({
		label: value.label,
		slug: `/szablon/${key}`,
	})), [])
	return (
		<Drawer
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: drawerWidth,
					boxSizing: "border-box",
					padding: '0.5rem'
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<Toolbar>
				<Link to="/" className="h-full w-full flex justify-center items-center p-4">
					<img alt="logo" src="/csma-logo.png" className="h-20 w-20" />
				</Link>
			</Toolbar>
			<List className="h-full flex flex-col justify-center gap-2">
				{links.map(({ label, slug }, index) => (
					<ListItem key={label} disablePadding>
						<Link to={slug}>
							<Button variant="outlined">
								<span className="uppercase font-light">{label}</span>
							</Button>
						</Link>
					</ListItem>
				))}
			</List>
		</Drawer>
	);
};
