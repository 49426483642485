import { Button } from "@mui/material";
import { PdfDocument } from "../molecues/pdf-document/pdf-document";
import React, { useMemo } from "react";
import { TemplateFormMap } from "infrastructure/routes/routes";

interface PdfTemplatePageContentProps {
	templateName: keyof typeof TemplateFormMap;
}

export const PdfTemplatePageContent = ({
	templateName,
}: PdfTemplatePageContentProps) => {
	const { element, label } = TemplateFormMap[templateName];
	const Form = useMemo(
		() =>
			React.createElement(element, {
				label,
				children: (
					<div className="pt-12 pb-6 px-6 flex justify-center items-center w-full h-screen flex-col gap-2">
						<PdfDocument />
						<div className="h-min w-full flex items-center">
							<Button type="submit" variant="contained" className="w-full">
								Drukuj
							</Button>
						</div>
					</div>
				),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[templateName]
	);

	return <div className="flex h-full flex-col md:flex-row">{Form}</div>;
};
