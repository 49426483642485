import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
} from "@react-pdf/renderer";
import { TextFallback } from "../../molecues/text-fallback/text-fallback";
import { printDateInPolish } from "utils/print-date-in-polish";
import { TextField, Divider, Chip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm, Controller } from "react-hook-form";
import { useGenerateDocument } from "utils/use-generate-document";
import { PdfDocumentContext } from "../../molecues/pdf-document/pdf-document";
import { FormPdfTemplate } from "components/templates/form-pdf-template";

Font.register({
	family: "Roman",
	src: "/fonts/times.ttf",
});

Font.register({
	family: "Roman-Bold",
	src: "/fonts/times-bold.ttf",
});

const styles = StyleSheet.create({
	leftHeader: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
		fontSize: "12px",
	},
	mainTitle: {
		fontFamily: "Roman-Bold",
	},
	regularText: {
		fontSize: "12px",
		textAlign: "justify",
		lineHeight: "2",
	},
	page: {
		padding: "36px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "16px",
	},
});

interface TemplateFields {
	parafia: string;
	l: string;
	imieNazwisko: string;
	tom: string;
	strona: string;
	numer: string;
	imieNazwiskoOjca: string;
	imieNazwiskoMatki: string;
	domPanienskiMatki: string;
	miejsceUrodzenia: string;
	dataUrodzenia: Date;
	dataChrztu: Date;
	parafiaChrztu: string;
	miejsceWystawieniaDokumentu: string;
	dataDokumentu: Date;
}

const PdfTemplate = (props: TemplateFields) => {
	return (
		<Document>
			<Page size="A5" style={styles.page} orientation="landscape">
				<View style={styles.leftHeader}>
					<Text style={{ borderBottom: "2px" }}>
						PARAFIA <TextFallback fallback="parafia">{props.parafia}</TextFallback>
					</Text>
					<Text>
						L. <TextFallback fallback="l">{props.l}</TextFallback>
					</Text>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						alignItems: "center",
					}}
				>
					<Text style={styles.mainTitle}>WYCIĄG Z KSIĘGI OCHRZCZONYCH</Text>
					<Text
						style={{
							fontSize: "10px",
							width: "100%",
							textAlign: "center",
						}}
					>
						(t. <TextFallback fallback="tom">{props.tom}</TextFallback>, str:{" "}
						<TextFallback fallback="strona">{props.strona}</TextFallback>, nr:{" "}
						<TextFallback fallback="numer">{props.numer}</TextFallback>)
					</Text>
				</View>
				<View style={{ width: "100%" }}>
					<View
						style={{ ...styles.regularText, width: "100%", textAlign: "center" }}
					>
						<TextFallback fallback="imię i nazwisko">
							{props.imieNazwisko}
						</TextFallback>
					</View>
					<View>
						<Text style={styles.regularText}>
							syn/córka{" "}
							<Text style={{ fontWeight: "bold" }}>
								<TextFallback fallback="imię i nazwisko ojca">
									{props.imieNazwiskoOjca}
								</TextFallback>
							</Text>{" "}
							i{" "}
							<TextFallback fallback="imię i nazwisko matki">
								{props.imieNazwiskoMatki}
							</TextFallback>{" "}
							z domu{" "}
							<TextFallback fallback="dom panieński matki">
								{props.domPanienskiMatki}
							</TextFallback>{" "}
							urodzony(a) w{" "}
							<TextFallback fallback="miejsce urodzenia">
								{props.miejsceUrodzenia}
							</TextFallback>
							, dnia{" "}
							<TextFallback fallback="data urodzenia">
								{printDateInPolish(props.dataUrodzenia)}
							</TextFallback>{" "}
							ochrzczony(a) został(a) według obrządku rzymskokatolickiego, dnia{" "}
							<TextFallback fallback="data Chrztu Św.">
								{printDateInPolish(props.dataChrztu)}
							</TextFallback>{" "}
							w kościele parafialnym{" "}
							<TextFallback fallback="parafia Chrztu Św.">
								{props.parafiaChrztu}
							</TextFallback>
							.
						</Text>
					</View>
				</View>
				<View
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "flex-start",
					}}
				>
					<Text style={{ fontSize: "12px" }}>
						<TextFallback fallback="miejsce wystawienia dokumentu">
							{props.miejsceWystawieniaDokumentu}
						</TextFallback>
						,{" "}
						<TextFallback fallback="data dokumentu">
							{printDateInPolish(props.dataDokumentu)}
						</TextFallback>
						r.
					</Text>
				</View>
			</Page>
		</Document>
	);
};

interface FormProps {
	children: React.ReactNode;
	label: string,
}

export const Form = (props: FormProps) => {
	const { children, label } = props;
	const formObj = useForm<TemplateFields>({
		defaultValues: {
			dataDokumentu: new Date(),
			miejsceWystawieniaDokumentu: "Pawlikowice",
			parafia: "pw. św. Michała Archanioła",
		},
	});
	const { register, control, watch, handleSubmit } = formObj;

	const { onSubmit, ...pdf } = useGenerateDocument({
		watch,
		template: (fields) => <PdfTemplate {...fields} />,
	});

	const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		handleSubmit(() => {
			onSubmit();
		})(e);
	};

	return (
		<form onSubmit={onFormSubmit} className="w-full">
			<FormPdfTemplate
				pdfSection={
					<PdfDocumentContext.Provider value={pdf}>
						{children}
					</PdfDocumentContext.Provider>
				}
				label={label}
			>
				<div className="w-full flex flex-col gap-4 overflow-screen py-12 px-6 overflow-scroll max-h-screen">
					<div className="flex flex-col md:flex-row gap-2">
						<TextField
							className="w-full"
							{...register("parafia")}
							id="outlined-basic"
							label="Parafia"
							variant="outlined"
						/>
						<TextField
							className="w-full"
							{...register("l")}
							id="outlined-basic"
							label="L"
							variant="outlined"
						/>
					</div>
					<Divider />
					<div className="flex gap-2 flex-col md:flex-row ">
						<TextField
							className="w-full"
							{...register("tom")}
							id="outlined-basic"
							label="Tom"
							variant="outlined"
						/>
						<TextField
							className="w-full"
							{...register("strona")}
							id="outlined-basic"
							label="Strona"
							variant="outlined"
						/>
						<TextField
							className="w-full"
							{...register("numer")}
							id="outlined-basic"
							label="Numer"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Ochrzczony(a)" />
					</Divider>
					<TextField
						{...register("imieNazwisko")}
						id="outlined-basic"
						label="Imię i nazwisko"
						variant="outlined"
					/>
					<Divider>
						<Chip label="Syn/córka..." />
					</Divider>
					<div className="flex gap-2 flex-col md:flex-row ">
						<TextField
							{...register("imieNazwiskoOjca")}
							id="outlined-basic"
							label="Imię i nazwisko ojca"
							variant="outlined"
							className="w-full"
						/>
						<Divider className="h-full hidden md:block" orientation="vertical" />
						<div className="flex flex-col gap-2 w-full">
							<TextField
								className="w-full"
								{...register("imieNazwiskoMatki")}
								id="outlined-basic"
								label="Imię i nazwisko matki"
								variant="outlined"
							/>
							<span className="text-xs">z domu</span>
							<TextField
								className="w-full"
								{...register("domPanienskiMatki")}
								id="outlined-basic"
								label="Dom panieński matki"
								variant="outlined"
							/>
						</div>
					</div>
					<Divider>
						<Chip label="Urodzony(a)..." />
					</Divider>
					<div className="flex gap-2 items-center flex-col md:flex-row ">
						<Controller
							control={control}
							name="dataUrodzenia"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data urodzenia"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						/>
						<span className="text-xs">w</span>
						<TextField
							className="w-full"
							{...register("miejsceUrodzenia")}
							id="outlined-basic"
							label="Miejsce urodzenia"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Ochrzczony(a)..." />
					</Divider>
					<div className="flex gap-2 items-center flex-col md:flex-row ">
						<Controller
							control={control}
							name="dataChrztu"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data chrztu"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						></Controller>
						<span className="text-xs">w kościele parafialnym</span>
						<TextField
							className="w-full"
							{...register("parafiaChrztu")}
							id="outlined-basic"
							label="Parafia chrztu"
							variant="outlined"
						/>
					</div>
					<Divider />
					<div className="flex gap-2 flex-col md:flex-row ">
						<TextField
							className="w-full"
							{...register("miejsceWystawieniaDokumentu")}
							id="outlined-basic"
							label="Miejsce wystawienia dokumentu"
							variant="outlined"
						/>
						<Controller
							control={control}
							name="dataDokumentu"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data dokumentu"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						></Controller>
					</div>
				</div>
			</FormPdfTemplate>
		</form>
	);
};
