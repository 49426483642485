import React from 'react';

interface FormPdfTemplateProps {
  children: React.ReactNode;
  pdfSection: React.ReactNode;
  label: string;
}

export const FormPdfTemplate = ({ children, pdfSection, label }: FormPdfTemplateProps) => {
  return <div className="flex flex-col md:flex-row w-full md:h-full">
    <div className="flex flex-col w-full overflow-scroll">
      <h1 className="uppercase text-2xl p-8 text-left">{label}</h1>
      {children}
    </div>
    {pdfSection}
  </div>
}