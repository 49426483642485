import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Control, FieldValues, Controller, Path } from "react-hook-form";
import { UseFormStateReturn } from "react-hook-form/dist/types";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form/dist/types/controller";

type RenderProps<T extends FieldValues, N extends Path<T>> = {
	field: ControllerRenderProps<T, N>,
	fieldState: ControllerFieldState;
	formState: UseFormStateReturn<T>;
}

type IFormTextFieldProps<T extends FieldValues, N extends Path<T>> = {
	name: N,
	control: Control<T>
	render: (props: RenderProps<T, N>) => React.ReactElement;
}

export const ControlledFormField = <T extends FieldValues, N extends Path<T>>(props: IFormTextFieldProps<T, N>) => {
	const { control, render, name } = props;

	return (
		<Controller
			render={render}
			name={name}
			control={control}
			rules={{ required: "TO POLE NIE MOŻE BYĆ PUSTE" }}
		/>
	);
}
