import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";
import { TextFallback } from "../../molecues/text-fallback/text-fallback";
import { printDateInPolish } from "utils/print-date-in-polish";
import { TextField, Divider, Chip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useGenerateDocument } from "utils/use-generate-document";
import { PdfDocumentContext } from "../../molecues/pdf-document/pdf-document";
import { FormPdfTemplate } from "components/templates/form-pdf-template";
import { FormTextField } from "../../../molecues/form-text-field/form-text-field";
import { FormDatePicker } from "../../../molecues/form-date-picker/form-date-picker";
import logo from "./logo.png";

Font.register({
	family: "Roman",
	src: "/fonts/times.ttf",
});

Font.register({
	family: "Roman-Bold",
	src: "/fonts/times-bold.ttf",
});

const styles = StyleSheet.create({
	dottedField: {
		borderBottom: '1px dotted black',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%',
	},
	dottedInlineField: {
		flexGrow: 1,
		width: '100%',
		textDecorationStyle: "dotted",
		textAlign: 'center'
	},
	dottedFieldContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row'
	},
	subDocument: {
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "8px",
		width: "100%",
		color: "#a9013d",
		borderColor: "#a9013d",
		fontSize: '10px',
		border: '1px solid #a9013d',
		borderRadius: '12px',
	},
	section: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "8px",
		width: "100%",
		color: "#a9013d",
		borderColor: "#a9013d",
		position: "relative",
	}
});
export interface TemplateFields {
	nazwiskoImie: string;
	imieNazwiskoMatki: string;
	imieNazwiskoOjca: string;
	dataUrodzenia: Date;
	miejsceUrodzenia: string;
	nrAktuChrztu: string;
	dataSlubu: Date;
	miejsceSlubu: string;
	imieNazwiskoWspolmalzonka: string;
	nrZapisuWKsiedze: string;
	dataWystawienia: Date;
	miejsceWystawienia: string;

	lpProt: string;
	lDz: string;
	parafiaAdresatNazwa: string;
	parafiaAdresatAdres: string;
	parafiaOdnotowujaca: string;
	dataAdnotacji: Date;
}
const PdfTemplate = (props: TemplateFields) => {
	return (
		<Document>
			<Page size="A5" style={{ display: 'flex', flexDirection: 'column', gap: '2px'}}>
			<View style={{...styles.subDocument, gap: '16px' }}>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '16px', minHeight: '60px' }}>
					<View style={{ width: '100%', border: '1px dotted #a9013d', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}} >
						<Text style={{fontSize: '8px'}}>Pieczęć parafii (adres zwrotny)</Text>
					</View>
					<View style={{ width: '100%', fontFamily: 'Roman-Bold'}}>
						<Text>ZAWIADOMIENIE PROBOSZCZA PARAFII CHRZTU O ZAWARCIU MAŁŻEŃSTWA I DOKONANEJ ADNOTACJI</Text>
					</View>
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%'}}>
					<View style={{ width: '100%' }}>
						<Text>Lp. prot. <TextFallback fallback={"lp. prot"}>{props.lpProt}</TextFallback></Text>
						<Text>L. dz. <TextFallback fallback={"l. dz."}>{props.lDz}</TextFallback></Text>
					</View>
					<View style={{ width: '100%' }}>
						<Text>Do Ks. Proboszcza Parafii Rzymskokatolickiej</Text>
						<Text>pw. <TextFallback fallback="parafia">{props.parafiaAdresatNazwa}</TextFallback></Text>
						<Text>w <TextFallback fallback="adres parafii">{props.parafiaAdresatAdres}</TextFallback></Text>
					</View>
				</View>
				<View style={{ width: '100%' }}>
					<Text style={{ fontFamily: 'Roman-Bold'}}>Po dokonaniu adnotacji w myśl kan. 1122 § 1 i 2 KPK proszę o zwrot niniejszego powiadomienia</Text>
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}}>
					<Text>Odnotowano w parafii <TextFallback fallback="parafia odnotowująca">{props.parafiaOdnotowujaca}</TextFallback></Text>
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '30px' }}>
					<Text style={{ width: '100%'}}>dnia <TextFallback fallback="data adnotacji">{printDateInPolish(props.dataAdnotacji)}</TextFallback></Text>
					<View style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<View style={{ width: '100%', borderBottom: '1px dotted #a9013d' }} />
						<Text style={{ fontSize: '8px'}}>Podpis duszpasterza i pieczęć</Text>
					</View>
				</View>
			</View>
			<View style={styles.subDocument}>
				<View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
					<View style={{ width: '100%', borderBottom: '1px solid #a9013d'}} />
					<Text style={{ fontFamily: 'Roman-Bold', width: '100%' }}>Uprzejmie zawiadamiam, że</Text>
					<View style={{ width: '100%', borderBottom: '1px solid #a9013d'}} />
				</View>
				<View style={styles.dottedField}>
					<TextFallback fallback="nazwisko i imię">{props.nazwiskoImie}</TextFallback>
				</View>
				<Text style={{ width: '100%' }}>
					<Text>syn/córka </Text>
					<TextFallback fallback="imię i nazwisko matki">{props.imieNazwiskoMatki}</TextFallback>
					<Text> oraz </Text>
					<TextFallback fallback="imię i nazwisko ojca">{props.imieNazwiskoOjca}</TextFallback>
				</Text>
				<Text style={{ width: '100%' }}>
					<Text>ur. dnia </Text>
						<TextFallback style={{ textDecoration: 'underline', textDecorationStyle: 'solid'}} fallback="data urodzenia">{printDateInPolish(props.dataUrodzenia)}</TextFallback>
					<Text style={{ width: '30px'}}> w </Text>
						<TextFallback style={{ textDecoration: 'underline', textDecorationStyle: 'solid'}} fallback="miejsce urodzenia">{props.miejsceUrodzenia}</TextFallback>
				</Text>
				<View style={styles.dottedFieldContainer}>
					<Text>akt chrztu nr </Text>
						<TextFallback  style={{ textDecoration: 'underline', textDecorationStyle: 'solid'}} fallback="numer aktu chrztu">{props.nrAktuChrztu}</TextFallback>
					<Text style={{ minWidth: '30px'}}> zawarł/a sakramentalny związek małżeński</Text>
				</View>
				<Text style={{ width: '100%' }}>
					<Text>dnia </Text>
					<TextFallback fallback="data ślubu">{printDateInPolish(props.dataSlubu)}</TextFallback>
					<Text>, w </Text>
					<TextFallback fallback="miejsce ślubu">{props.miejsceSlubu}</TextFallback>
				</Text>
				<Text style={{ width: '100%' }}>
					<Text>zapisany w księdze zaślubionych tutejszej parafii pod nr </Text>
					<TextFallback fallback="numer zapisu w księdze">{props.nrZapisuWKsiedze}</TextFallback>
				</Text>
				<View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
					<Image src={logo} style={{ width: "100	px" }} />
				</View>
				<View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<Text style={{ width: '100%'}}><TextFallback fallback="miejsce wystawienia">{props.miejsceWystawienia}</TextFallback>, dnia <TextFallback fallback="data wystawienia">{printDateInPolish(props.dataWystawienia)}</TextFallback></Text>
					<View style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<View style={{ width: '100%', borderBottom: '1px dotted black' }} />
						<Text style={{ fontSize: '8px'}}>Podpis duszpasterza i pieczęć</Text>
					</View>
				</View>
			</View>
			</Page>
		</Document>
	);
};

interface FormProps {
	children: React.ReactNode;
	label: string;
}

export const ZawiadomienieProboszczaOZawarciuMalzenstwaIAdnotacja = (
	props: FormProps
) => {
	const { children, label } = props;
	const formObj = useForm<TemplateFields>({
		defaultValues: {
			dataWystawienia: new Date(),
			miejsceWystawienia: "Pawlikowice",
		},
	});
	const { control, watch, handleSubmit } = formObj;

	const { onSubmit, ...pdf } = useGenerateDocument({
		watch,
		template: (fields) => <PdfTemplate {...fields} />,
	});

	const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		handleSubmit(() => {
			onSubmit();
		})(e);
	};

	return (
		<form onSubmit={onFormSubmit} className="w-full px-4">
			<FormPdfTemplate
				pdfSection={
					<PdfDocumentContext.Provider value={pdf}>
						{children}
					</PdfDocumentContext.Provider>
				}
				label={label}
			>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-4 border border-black p-4	rounded">
					<h2 className="w-full text-left">(1/2) ZAWIADOMIENIE PROBOSZCZA O ZAWARCIU MAŁŻEŃSTWA</h2>
					<div className="flex gap-4 w-full">
						<FormTextField className="w-full" control={control} name="lpProt" label="Lp. prot."/>
						<FormTextField className="w-full" control={control} name="lDz" label="L. dz."/>
					</div>
					<Divider><Chip label="Parafia - adresat"/></Divider>
					parafia pw <FormTextField control={control} name="parafiaAdresatNazwa" label="Parafia - adresat" />
					w <FormTextField control={control} name="parafiaAdresatAdres" label="Adres parafii" />
					<Divider><Chip label="Parafia odnotowująca"/></Divider>
					<FormTextField control={control} name="parafiaOdnotowujaca" label="Parafia odnotowująca" />
					<FormDatePicker control={control} name="dataAdnotacji" label="Data adnotacji" />
					<Divider />
					</div>
					<div className="flex flex-col gap-4 border border-black p-4	rounded">
					<h2 className="w-full text-left">(2/2) ADNOTACJA</h2>
					<FormTextField control={control} name="nazwiskoImie" label="Nazwisko i imię" />
					<Divider><Chip label="Syn/córka ..."/></Divider>
					<div className="flex gap-4 w-full">
						<FormTextField className="w-full" control={control} name="imieNazwiskoMatki" label="Imię i nazwisko matki"/>
						<FormTextField className="w-full" control={control} name="imieNazwiskoOjca" label="Imię i nazwisko ojca" />
					</div>
					<Divider><Chip label="Data i miejsce urodzenia"/></Divider>
					<div className="flex gap-4 w-full items-center">
						<FormDatePicker control={control} name="dataUrodzenia" label="Data urodzenia" />
						w
						<FormTextField className="w-full" control={control} name="miejsceUrodzenia" label="Miejsce urodzenia" />
					</div>
					<Divider><Chip label="Chrzest święty"/></Divider>
					<FormTextField className="w-full" control={control} name="nrAktuChrztu" label="Numer aktu chrztu św." />
					<Divider><Chip label="Ślub"/></Divider>
					<div className="flex gap-4 w-full items-center">
						<FormDatePicker control={control} name="dataSlubu" label="Data ślubu" />
						w
						<FormTextField className="w-full" control={control} name="miejsceSlubu" label="Miejsce ślubu" />
					</div>
					<FormTextField control={control} name="imieNazwiskoWspolmalzonka" label="Imię i nazwisko współmałżonka" />
					<FormTextField control={control} name="nrZapisuWKsiedze" label="Numer zapisu w księdze" />
					<Divider><Chip label="Dane dokumentu"/></Divider>
					<div className="flex gap-4 w-full">
						<FormDatePicker control={control} name="dataWystawienia" label="Data wystawienia dokumentu" />
						<FormTextField className="w-full" control={control} name="miejsceWystawienia" label="Miejsce wystawienia dokumentu" />
					</div>
					</div>
				</div>
			</FormPdfTemplate>
		</form>
	);
};
