import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
} from "@react-pdf/renderer";
import { TextFallback } from "../../molecues/text-fallback/text-fallback";
import { printDateInPolish } from "utils/print-date-in-polish";
import { Divider, Chip } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useGenerateDocument } from "utils/use-generate-document";
import { PdfDocumentContext } from "../../molecues/pdf-document/pdf-document";
import { FormPdfTemplate } from "components/templates/form-pdf-template";
import { PersonalForm } from "./personal-form";
import { FormTextField } from "../../../molecues/form-text-field/form-text-field";
import { FormDatePicker } from "../../../molecues/form-date-picker/form-date-picker";

Font.register({
	family: "Roman",
	src: "/fonts/times.ttf",
});

Font.register({
	family: "Roman-Bold",
	src: "/fonts/times-bold.ttf",
});

const styles = StyleSheet.create({
	leftHeader: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
		fontSize: "12px",
	},
	mainTitle: {
		fontFamily: "Roman-Bold",
	},
	regularText: {
		fontSize: "12px",
		textAlign: "justify",
		lineHeight: "2",
	},
	page: {
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "8px",
		width: "100%",
		color: "#a9013d",
		borderColor: "#a9013d",
	},
	section: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "8px",
		width: "100%",
		color: "#a9013d",
		borderColor: "#a9013d",
		position: "relative",
	}
});

export interface PerPersonFields {
	imieNazwisko: string;
	imieMatki: string;
	imieOjca: string;
	dataUrodzenia: Date;
	miejsceUrodzenia: string;
	stanCywilny: string;
	wyznanie: string;
	adresZamieszkania: string;
}

export interface TemplateFields {
	miejscowoscDokumentu: string;
	dataWystawienia: Date;
	parafiaDocelowa: string;
	miejscowoscParafiiDocelowej: string;
	narzeczony: PerPersonFields;
	narzeczona: PerPersonFields;
}

const TableCell = ({ children }: { children: React.ReactNode }) => (
	<View
		style={{
			width: "100%",
			height: "100%",
			paddingLeft: "4px",
			borderLeft: "1px solid #a9013d",
			paddingVertical: "4px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
		{children}
	</View>
);

const TableRow = ({
	children,
	label,
}: {
	children: React.ReactNode;
	label: string;
}) => (
	<View
		style={{
			borderTop: "1px solid #a9013d",
			display: "flex",
			flexDirection: "row",
			width: "100%",
			fontSize: "8px",
			alignItems: "center",
			justifyContent: "space-evenly",
		}}
	>
		<View
			style={{
				paddingRight: "8px",
				width: "200px",
				height: "100%",
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "center",
			}}
		>
			<Text style={{ paddingTop: "4px", paddingBottom: "4px" }}>{label}</Text>
		</View>
		{children}
	</View>
);

const PieczecParafii = () => (
	<View
		style={{
			width: "400px",
			height: "60px",
			border: "1px dotted #a9013d",
			borderRadius: "12px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}
	>
		<Text style={{ fontSize: "8px" }}>Pieczęć parafii</Text>
	</View>
);

const PodpisDuszpasterza = () => (
	<View
		style={{
			fontSize: "10px",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			gap: "64px",
		}}
	>
		<View
			style={{
				height: "50px",
				width: "50px",
				border: "1px dotted #a9013d",
				borderRadius: "50%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Text style={{ fontSize: "8px" }}>(L.S.)</Text>
		</View>
		<View style={{ width: "150px", textAlign: "center" }}>
			<Text style={{ borderTop: "1px dotted #a9013d", fontSize: '8px', paddingTop: '2px' }}>podpis duszpasterza</Text>
		</View>
	</View>
);

const PdfTemplate = (props: TemplateFields) => {
	return (
		<Document>
			<Page size="A5" style={styles.page}>
				<View style={styles.section}>
				<View>
					<Text style={{ fontFamily: "Roman-Bold", fontSize: "11px" }}>
						PROŚBA O OGŁOSZENIE ZAPOWIEDZI PRZEDMAŁŻEŃSKICH
					</Text>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						gap: "0px",
					}}
				>
					<PieczecParafii />
					<View
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
§					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							width: "300px",
							fontSize: "12px",
							textAlign: "center",
							gap: "12px",
						}}
					>
						<View style={{ width: "100%" }}>
							<TextFallback fallback="miejscowość wystawienia">
								{props.miejscowoscDokumentu}
							</TextFallback>
							<Text
								style={{
									fontSize: "8px",
									borderTop: "1px dotted #a9013d",
									paddingTop: "2px",
								}}
							>
								Miejscowość
							</Text>
						</View>
						<View style={{ width: "100%" }}>
							<TextFallback fallback="data wystawienia">
								{printDateInPolish(props.dataWystawienia)}
							</TextFallback>
							<Text
								style={{
									fontSize: "8px",
									borderTop: "1px dotted #a9013d",
									paddingTop: "2px",
								}}
							>
								Data
							</Text>
						</View>
					</View>
				</View>
				<View style={{ display: "flex", flexDirection: "row" }}>
					<View
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							fontFamily: "Roman-Bold",
							height: "100%",
						}}
					>
						<Text style={{ fontSize: "10px" }}>
							Do Księdza Proboszcza Parafii rzymskokatolickiej
						</Text>
					</View>
				</View>
				<View style={{ fontSize: "10px", display: "flex", flexDirection: "row", fontFamily: 'Roman-Bold' }}>
					<Text>
						pw.{" "}
						<TextFallback fallback="parafia docelowa">
							{props.parafiaDocelowa}
						</TextFallback>
					</Text>
					<Text>
						{" "}
						w{" "}
						<TextFallback fallback="miejscowość parafii">
							{props.miejscowoscParafiiDocelowej}
						</TextFallback>
					</Text>
				</View>
					<View style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '10px', textAlign: 'center'}}>
						<Text>Uprzejmie proszę o ogłoszenie w kościele parafialnym Księdza Proboszcza następujących zapowiedzi przedmałżeńskich:</Text>
					</View>
				<View style={{ width: "100%" }}>
					<TableRow label="">
						<TableCell>
							<Text>NARZECZONY</Text>
						</TableCell>
						<TableCell>
							<Text>NARZECZONA</Text>
						</TableCell>
					</TableRow>
					<TableRow label="Imię i nazwisko">
						<TableCell>
							<TextFallback fallback="imię i nazwisko">
								{props.narzeczony?.imieNazwisko}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="imię i nazwisko">
								{props.narzeczona?.imieNazwisko}
							</TextFallback>
						</TableCell>
					</TableRow>
					<TableRow label="Imiona rodziców">
						<TableCell>
							<TextFallback fallback="imię i nazwisko matki">
								{props.narzeczony?.imieMatki}
							</TextFallback>
							<TextFallback fallback="imię i nazwisko ojca">
								{props.narzeczony?.imieOjca}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="imię i nazwisko matki">
								{props.narzeczona?.imieMatki}
							</TextFallback>
							<TextFallback fallback="imię i nazwisko ojca">
								{props.narzeczona?.imieOjca}
							</TextFallback>
						</TableCell>
					</TableRow>
					<TableRow label="Data i miejsce urodzenia">
						<TableCell>
							<TextFallback fallback="data urodzenia">
								{printDateInPolish(props.narzeczony?.dataUrodzenia)}
							</TextFallback>
							<TextFallback fallback="miejsce urodzenia">
								{props.narzeczony?.miejsceUrodzenia}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="data urodzenia">
								{printDateInPolish(props.narzeczona?.dataUrodzenia)}
							</TextFallback>
							<TextFallback fallback="miejsce urodzenia">
								{props.narzeczona?.miejsceUrodzenia}
							</TextFallback>
						</TableCell>
					</TableRow>
					<TableRow label="Stan cywilny">
						<TableCell>
							<TextFallback fallback="stan cywilny">
								{props.narzeczony?.stanCywilny}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="stan cywilny">
								{props.narzeczona?.stanCywilny}
							</TextFallback>
						</TableCell>
					</TableRow>
					<TableRow label="Wyznanie">
						<TableCell>
							<TextFallback fallback="wyznanie">
								{props.narzeczony?.wyznanie}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="wyznanie">
								{props.narzeczona?.wyznanie}
							</TextFallback>
						</TableCell>
					</TableRow>
					<TableRow label="Adres zamieszkania">
						<TableCell>
							<TextFallback fallback="adres zamieszkania">
								{props.narzeczony?.adresZamieszkania}
							</TextFallback>
						</TableCell>
						<TableCell>
							<TextFallback fallback="adres zamieszkania">
								{props.narzeczona?.adresZamieszkania}
							</TextFallback>
						</TableCell>
					</TableRow>
				</View>
				<View style={{ width: "100%", display: 'flex', alignItems: 'flex-end' }}>
					<PodpisDuszpasterza />
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						gap: "1px",
					}}
				>
					<View style={{ borderBottom: "1px solid #a9013d" }} />
					<View style={{ borderBottom: "1px solid #a9013d" }} />
				</View>
				</View>
				<View style={styles.section}>
				<View>
					<Text style={{ fontFamily: "Roman-Bold", fontSize: "11px" }}>
						POTWIERDZENIE OGŁOSZENIA ZAPOWIEDZI PRZEDMAŁŻEŃSKICH
					</Text>
				</View>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						gap: "0px",
					}}
				>
					<PieczecParafii />
					<View
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							width: "300px",
							fontSize: "12px",
							textAlign: "center",
							gap: "12px",
						}}
					>
						<View style={{ width: "100%" }}>
							<Text style={{ color: 'transparent' }}>PLACEHOLDER</Text>
							<Text
								style={{
									fontSize: "8px",
									borderTop: "1px dotted #a9013d",
									paddingTop: "2px",
								}}
							>
								Miejscowość
							</Text>
						</View>
						<View style={{ width: "100%" }}>
						<Text style={{ color: 'transparent' }}>PLACEHOLDER</Text>
							<Text
								style={{
									fontSize: "8px",
									borderTop: "1px dotted #a9013d",
									paddingTop: "2px",
								}}
							>
								Data
							</Text>
						</View>
					</View>
				</View>
				<View style={{ fontSize: "8px", width: "100%", display: 'flex', flexDirection: 'column' }}>
					<View style={{ display: 'flex', flexDirection: 'row', gap: '0px'}}>
						<View style={{ width: '100%' }}>
							<Text>Zaświadczam, ze zapowiedzi zostały ogłoszone w dniach</Text>
						</View>
						<View style={{ borderBottom: "1px dotted #a9013d", width: "100%" }} />
					</View>
					<Text>Zadnych przeszkód kanonicznych nie zgłoszono</Text>
				</View>
				<View style={{ width: "100%", display: 'flex', alignItems: 'flex-end' }}>
					<PodpisDuszpasterza />
				</View>
				</View>
			</Page>
		</Document>
	);
};

interface FormProps {
	children: React.ReactNode;
	label: string;
}

export const ProsbaOOgloszenieZapowiedziPrzedmalzenskich = (
	props: FormProps
) => {
	const { children, label } = props;
	const formObj = useForm<TemplateFields>({
		defaultValues: {
			dataWystawienia: new Date(),
			miejscowoscDokumentu: "Pawlikowice",
		},
	});
	const { register, control, watch, handleSubmit, formState: { errors } } = formObj;

	const { onSubmit, ...pdf } = useGenerateDocument({
		watch,
		template: (fields) => <PdfTemplate {...fields} />,
	});

	const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		handleSubmit(() => {
			onSubmit();
		})(e);
	};

	return (
		<form onSubmit={onFormSubmit} className="w-full px-4">
			<FormPdfTemplate
				pdfSection={
					<PdfDocumentContext.Provider value={pdf}>
						{children}
					</PdfDocumentContext.Provider>
				}
				label={label}
			>
				<div className="flex flex-col gap-4">
					<div className="flex gap-2">
						<FormTextField
							className="w-full"
							control={control}
							name="miejscowoscDokumentu"
							id="outlined-basic"
							label="Miejscowość dokumentu"
							variant="outlined"
						/>
						<FormDatePicker
							control={control}
							name="dataWystawienia"
							label="Data wystawienia"
						/>
					</div>
					<Divider>
						<Chip label="Adresat" />
					</Divider>
					<div className="flex gap-2 items-center">
						<span className="text-xs">pw</span>
						<FormTextField
							className="w-full"
							control={control}
							name="parafiaDocelowa"
							id="outlined-basic"
							label="Parafia"
							variant="outlined"
						/>
						<span className="text-xs">w</span>
						<FormTextField
							control={control}
							className="w-full"
							name="miejscowoscParafiiDocelowej"
							id="outlined-basic"
							label="Miejscowość"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="NARZECZENI" />
					</Divider>
					<div className="flex w-full gap-4">
							<FormProvider {...formObj}>
								<PersonalForm parent="narzeczony" label="Narzeczony" />
								<Divider className="h-full" orientation="vertical" />
								<PersonalForm parent="narzeczona" label="Narzeczona" />
						</FormProvider>
					</div>
				</div>
			</FormPdfTemplate>
		</form>
	);
};
