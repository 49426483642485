import React from 'react';
import {
	Text,
} from "@react-pdf/renderer";
import { Style } from "types/style";


export const TextFallback = ({
	children,
	fallback,
	style,
}: {
	children: string;
	fallback: string;
	style?: Style
}) =>
	children ? (
		<Text style={{...style, fontFamily: 'Roman-Bold', color: 'black' }}>{children}</Text>
	) : (
		<Text style={{ color: "red" }}>{`{${fallback}}`}</Text>
	);