import { usePDF } from "@react-pdf/renderer";
import debounce from "lodash.debounce";
import { useState, useMemo, useEffect } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";
import printJS from "print-js";

type useGenerateDocumentProps<T extends FieldValues> = {
  watch: UseFormWatch<T>;
  template: (fields: T) => JSX.Element;
}

export const useGenerateDocument = <T extends FieldValues>(props: useGenerateDocumentProps<T>) => {
  const { watch, template } = props;
  const allFields = watch();

  const [trigger, setTrigger] = useState({});
  const [document, setDocument] = useState(template(allFields));
  const [pdf, update] = usePDF({ document });


	const handleFormChange = useMemo(() => {
		const onFormChange = () => {
			setTrigger({});
		};
		return debounce(onFormChange, 1000);
	}, []);

	useEffect(() => {
		handleFormChange();
	}, [allFields, handleFormChange]);

  useEffect(() => {
		if(allFields) setDocument(template(allFields));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trigger]);

	useEffect(() => {
		update();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document]);

	const onSubmit = () => {
		printJS(pdf.url!)
	}

  return {
		onSubmit,
		pdf,
	};
}