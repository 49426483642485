import { createBrowserRouter } from "react-router-dom";
import { MainPage } from "components/pages/main-page";
import { PdfTemplatePage } from "components/pages/pdf-template-page";
import { Form } from "components/organisms/templates/wyciag-z-ksiegi-ochrzczonych/wyciag-z-ksiegi-ochrzczonych";
import { ZawiadomienieOMalzenstwie } from "components/organisms/templates/zawiadomienie-o-malzenstwie/zawiadomienie-o-malzenstwie";
import { ProsbaOOgloszenieZapowiedziPrzedmalzenskich } from "components/organisms/templates/prosba-o-ogloszenie-zapowiedzi-przedmalzenskich/prosba-o-ogloszenie-zapowiedzi-przedmalzenskich";
import {
  ZawiadomienieProboszczaOZawarciuMalzenstwaIAdnotacja
} from "components/organisms/templates/zawiadomienie-proboszcza-o-zawarciu-malzenstwa-i-adnotacji/zawiadomienie-proboszcza-o-zawarciu-malzenstwa-i-adnotacji";

export const TemplateFormMap = {
	wyciag: {
    element: Form,
    label: 'wyciąg z księgi ochrzczonych'
  },
  'zawiadomienie-o-malzenstwie': {
    element: ZawiadomienieOMalzenstwie,
    label: 'zawiadomienie o małżeństwie'
  },
  'prosba-o-ogloszenie-zapowiedzi-przedmalzenskich': {
    element: ProsbaOOgloszenieZapowiedziPrzedmalzenskich,
    label: 'prośba o ogłoszenie zapowiedzi przedmałżeńskich',
  },
  'zawiadomienie-proboszcza-o-zawarciu-malzenstwa-i-adnotacja': {
    element: ZawiadomienieProboszczaOZawarciuMalzenstwaIAdnotacja,
    label: 'zawiadomienie proboszcza o zawarciu małżeństwa i adnotacja',
  }
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/szablon/:templateName",
    element: <PdfTemplatePage />
  }
]);