// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAskoG33wc_jduff4iZm461HwVKEBTmkAI",
  authDomain: "e-kancelaria-eb3e1.firebaseapp.com",
  projectId: "e-kancelaria-eb3e1",
  storageBucket: "e-kancelaria-eb3e1.appspot.com",
  messagingSenderId: "494780011465",
  appId: "1:494780011465:web:180862f64d94743112ce18",
  measurementId: "G-CXTZ7JHCZ1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);