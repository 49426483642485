		import React from "react";
		import { TextField, TextFieldProps } from "@mui/material";
		import { Control, FieldValues, Path } from "react-hook-form";
		import { ControlledFormField } from "../controlled-form-field/controlled-form-field";

		type IFormTextFieldProps<T extends FieldValues> = TextFieldProps & {
				control: Control<T, any>;
				name: Path<T>;
		}

		export const FormTextField = <T extends FieldValues,>(props: IFormTextFieldProps<T>) => {
			const { control, name, ...textFieldProp } = props;

			return (
					<ControlledFormField
						name={name}
						control={control}
						render={({ field: { value, ...restField}, fieldState }) => (
							<TextField {...textFieldProp} {...restField} value={ value || ''} error={fieldState.invalid} helperText={fieldState.error?.message}  />
						)}
					/>
			);
		}
