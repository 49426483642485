import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { dateIsValid } from "./data-is-valid";

export const printDateInPolish = (date?: Date) => {
	return date && dateIsValid(date)
		? format(date, "d MMMM yyyy", {
				locale: pl,
		  })
		: "";
};