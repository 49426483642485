import React from "react";
import { Chip, Divider, TextField } from "@mui/material";
import { Controller, useFormContext, Path } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { PerPersonFields, TemplateFields } from "./prosba-o-ogloszenie-zapowiedzi-przedmalzenskich";
import { FormTextField } from "../../../molecues/form-text-field/form-text-field";
import { FormDatePicker } from "../../../molecues/form-date-picker/form-date-picker";

interface IPersonalFormProps<T extends keyof TemplateFields> {
	parent: T;
	label: string;
}

type DynamicForm = {
	[key in keyof TemplateFields]: PerPersonFields
};

export function PersonalForm<T extends keyof TemplateFields>(props: IPersonalFormProps<T>) {
	const { parent, label } = props;
	const { register, control } = useFormContext<DynamicForm>();
	return (
		<div className="w-full flex flex-col gap-4">
			<Divider>
				<Chip label={label} />
			</Divider>
			<FormTextField
				control={control}
				name={`${parent}.imieNazwisko`}
				className="w-full"
				id="outlined-basic"
				label="Imię i nazwisko"
				variant="outlined"
			/>
			<Divider>
				<Chip label="Rodzice" />
			</Divider>
			<div className="flex gap-4">
				<FormTextField
					control={control}
					name={`${parent}.imieMatki`}
					className="w-full"
					id="outlined-basic"
					label="Imię matki"
					variant="outlined"
				/>
				<FormTextField
					control={control}
					name={`${parent}.imieOjca`}
					className="w-full"
					id="outlined-basic"
					label="Imię ojca"
					variant="outlined"
				/>
			</div>
			<Divider>
				<Chip label="Data i miejsce urodzenia" />
			</Divider>
			<div className="flex gap-4">
				<FormDatePicker
					control={control}
					name={`${parent}.dataUrodzenia`}
					label="Data urodzenia"
				/>
				<FormTextField
					control={control}
					name={`${parent}.miejsceUrodzenia`}
					className="w-full"
					id="outlined-basic"
					label="Miejsce urodzenia"
					variant="outlined"
				/>
			</div>
			<Divider>
				<Chip label="Pozostałe" />
			</Divider>
			<FormTextField
				control={control}
				name={`${parent}.stanCywilny`}
				className="w-full"
				id="outlined-basic"
				label="Stan cywilny"
				variant="outlined"
			/>
			<FormTextField
				control={control}
				name={`${parent}.wyznanie`}
				className="w-full"
				id="outlined-basic"
				label="Wyznanie"
				variant="outlined"
			/>
			<FormTextField
				control={control}
				name={`${parent}.adresZamieszkania`}
				className="w-full"
				id="outlined-basic"
				label="Adres zamieszkania"
				variant="outlined"
			/>
		</div>
	);
}
