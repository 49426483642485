import React from 'react';

interface MainTemplateProps {
  sidebar: React.ReactNode;
  mainContent: React.ReactNode;
}

export const MainTemplate = ( { sidebar, mainContent }: MainTemplateProps) => (
  <div className="flex w-full">
    {sidebar}
    <main className='w-full md:h-screen overflow-hidden'>
      {mainContent}
    </main>
  </div>
)