import React from "react";
import { TextField } from "@mui/material";
import { Control, FieldValues, Path } from "react-hook-form";
import { ControlledFormField } from "../controlled-form-field/controlled-form-field";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

type IFormDatePickerProps<T extends FieldValues> = {
	control: Control<T>;
	name: Path<T>;
	label: string;
}

export const FormDatePicker = <T extends FieldValues,>(props: IFormDatePickerProps<T>) => {
	const { control, name, label } = props;

	return (
		<ControlledFormField
			name={name}
			control={control}
			render={({ field: { value, ...restField}, fieldState }) => {

				return <DatePicker
					{...restField}
					value={ value || null }
					label={label}
					inputFormat="dd-MM-yyyy"
					renderInput={(inputProps) => (
						<TextField
							{...inputProps}
							className="w-full"
							type="date"
							error={fieldState.invalid}
							helperText={fieldState.error?.message}
							inputProps={{ ...inputProps.inputProps, placeholder: "dd-mm-rrrr" }}
						/>
					)}
				/>
			}}
		/>
	);
}
