import { router } from 'infrastructure/routes/routes';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import { analytics } from 'infrastructure/firebase/firebase';
import { logEvent } from "firebase/analytics";
import { withAuthenticationRequired } from "@auth0/auth0-react";

logEvent(analytics, 'app_loaded', {
  content_type: '',
  content_id: new Date(),
})

function App() {

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default withAuthenticationRequired(App);
