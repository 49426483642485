import { CircularProgress } from "@mui/material";
import ReactPDF from "@react-pdf/renderer";
import { createContext, useContext } from "react";

interface PdfDocumentContextType {
	pdf: ReactPDF.UsePDFInstance;
}

export const PdfDocumentContext = createContext<PdfDocumentContextType>(
	{} as PdfDocumentContextType
);

export const PdfDocument = () => {
	const { pdf } = useContext(PdfDocumentContext);

	return (
		<>
			{pdf.loading ? (
				<CircularProgress />
			) : (
				<object
					title="Dokument"
					className="w-full grow h-fit"
					data={`${pdf.url}#toolbar=0&navpanes=0&view=FitH` || ""}
					type="application/pdf"
				/>
			)}
		</>
	);
};
