import React from 'react'
import { Sidebar } from 'components/organisms/sidebar/sidebar'
import { MainTemplate } from 'components/templates/main-template'
import { HomePageContent } from 'components/organisms/home-page-content/home-page-content'

const drawerWidth = 200;

export const MainPage = () => {
  return (
    <MainTemplate
      sidebar={<Sidebar drawerWidth={drawerWidth} />}
      mainContent={<HomePageContent />}
    />
  )
}