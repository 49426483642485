import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";
import { TextFallback } from "../../molecues/text-fallback/text-fallback";
import { printDateInPolish } from "utils/print-date-in-polish";
import { TextField, Divider, Chip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm, Controller } from "react-hook-form";
import { useGenerateDocument } from "utils/use-generate-document";
import { PdfDocumentContext } from "../../molecues/pdf-document/pdf-document";
import { FormPdfTemplate } from "components/templates/form-pdf-template";
import logo from "./logo-dokument.png";

Font.register({
	family: "Roman",
	src: "/fonts/times.ttf",
});

Font.register({
	family: "Roman-Bold",
	src: "/fonts/times-bold.ttf",
});

const styles = StyleSheet.create({
	leftHeader: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		width: "100%",
		fontSize: "12px",
	},
	mainTitle: {
		fontFamily: "Roman-Bold",
	},
	regularText: {
		fontSize: "12px",
		textAlign: "justify",
		lineHeight: "2",
	},
	page: {
		padding: "34px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontFamily: "Roman",
		gap: "8px",
		width: "100%",
		color: "#a9013d",
		borderColor: "#a9013d",
	},
});

interface TemplateFields {
	miejscowoscDokumentu: string;
	dataWystawienia: Date;
	parafiaDocelowa: string;
	miejscowoscParafiiDocelowej: string;
	numerLicencji: string;
	dataLicencji: Date;
	imieNazwiskoMeza: string;
	imieNazwiskoZony: string;
	dataSlubu: Date;
	miejsceSlubu: string;
	imieNazwiskoAsystujacego: string;
	funkcjaAsystentujacego: string;
	imieNazwiskoSwiadka1: string;
	adresSwiadka1: string;
	imieNazwiskoSwiadka2: string;
	adresSwiadka2: string;
	numerAktuMalzenstwa: string;
	dataWyslaniaZawiadomienia: Date;
}

const TableRow = ({
	children,
	label,
}: {
	children: React.ReactNode;
	label: string;
}) => (
	<View
		style={{
			borderTop: "1px solid #a9013d",
			display: "flex",
			flexDirection: "row",
			width: "100%",
			fontSize: "10px",
			alignItems: "center",
		}}
	>
		<View
			style={{
				borderRight: "1px solid #a9013d",
				paddingRight: "8px",
				width: "150px",
				height: "100%",
				display: "flex",
				alignItems: "flex-start",
				justifyContent: "center",
			}}
		>
			<Text style={{ paddingTop: "4px", paddingBottom: "4px" }}>{label}</Text>
		</View>
		<View style={{ width: "100%", paddingLeft: '4px' }}>{children}</View>
	</View>
);

const PdfTemplate = (props: TemplateFields) => {
	return (
		<Document>
			<Page size="A5" style={styles.page}>
				<View style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '32px' }}>
					<View
						style={{
							width: "100%",
							height: "75px",
							border: "1px dotted #a9013d",
							borderRadius: "12px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Text style={{ fontSize: "8px" }}>Pieczęć adresowa parafii</Text>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							width: "100%",
							fontSize: "12px",
							textAlign: 'center',
							gap: '12px'
						}}
					>
						<View style={{ width: '100%'}}>
							<TextFallback fallback="miejscowość wystawienia">
								{props.miejscowoscDokumentu}
							</TextFallback>
							<Text style={{ fontSize: '8px', borderTop: '1px dotted #a9013d', paddingTop: '2px'}}>Miejscowość</Text>
						</View>
						<View style={{ width: '100%' }}>
						<TextFallback fallback="data wystawienia">
							{printDateInPolish(props.dataWystawienia)}
						</TextFallback>
						<Text style={{ fontSize: '8px', borderTop: '1px dotted #a9013d', paddingTop: '2px'}}>Data</Text>
						</View>
					</View>
				</View>
				<View style={{ display: "flex", flexDirection: "row" }}>
					<View>
						<Image src={logo} style={{ height: "50px" }} />
					</View>
					<View
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column",
							fontFamily: "Roman-Bold",
							height: "100%",
						}}
					>
						<Text style={{ fontSize: "14px" }}>ZAWIADOMIENIE O MAŁŻEŃSTWIE</Text>
						<Text style={{ fontSize: "14px" }}>ZAWARTYM NA PODSTAWIE LICENCJI</Text>
					</View>
				</View>
				<View style={{ fontSize: "12px" }}>
					<Text>Do Księdza Proboszcza Parafii rzymskokatolickiej</Text>
					<Text>
						pw.{" "}
						<TextFallback fallback="parafia docelowa">
							{props.parafiaDocelowa}
						</TextFallback>
					</Text>
					<Text>
						w{" "}
						<TextFallback fallback="miejscowość parafii">
							{props.miejscowoscParafiiDocelowej}
						</TextFallback>
					</Text>
				</View>
				<View style={{ fontSize: "10px" }}>
					<Text>
						Uprzejmie zawiadamiam, że na podstawie licencji wydanej przez waszą
						parafię zostało zawarte małżeństwo pomiędzy następującymi osobami:
					</Text>
				</View>
				<View style={{ width: "100%" }}>
					<TableRow label="Licencja: numer i data">
						<View style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
							<TextFallback fallback="numer licencji">
								{props.numerLicencji}
							</TextFallback>
							<TextFallback fallback="data licencji">
								{printDateInPolish(props.dataLicencji)}
							</TextFallback>
						</View>
					</TableRow>
					<TableRow label="Imię i nazwisko męża">
						<View style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
							<TextFallback fallback="imię i nazwisko męża">
								{props.imieNazwiskoMeza}
							</TextFallback>
						</View>
					</TableRow>
					<TableRow label="Imię i nazwisko żony">
						<View style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
							<TextFallback fallback="imię i nazwisko żony">
								{props.imieNazwiskoZony}
							</TextFallback>
						</View>
					</TableRow>
					<TableRow label="Data i miejsce (kościół) ślubu">
						<View>
							<TextFallback fallback="data ślubu">
								{printDateInPolish(props.dataSlubu)}
							</TextFallback>
							<TextFallback fallback="miejsce ślubu">
								{props.miejsceSlubu}
							</TextFallback>
						</View>
					</TableRow>
					<TableRow label="Imię i nazwisko asystującego; funkcja">
						<View>
							<TextFallback fallback="imię i nazwisko asystującego">
								{props.imieNazwiskoAsystujacego}
							</TextFallback>
							<TextFallback fallback="funkcja asystującego">
								{props.funkcjaAsystentujacego}
							</TextFallback>
						</View>
					</TableRow>
					<TableRow label="Imiona i nazwiska świadków, adresy zamieszkania">
						<View style={{ borderBottom: "1px solid #a9013d", width: "100%", marginLeft: '-4px', paddingLeft: '4px', paddingVertical: '4px' }}>
							<TextFallback fallback="imię i nazwisko świadka 1">
								{props.imieNazwiskoSwiadka1}
							</TextFallback>
							<TextFallback fallback="adres świadka 1">
								{props.adresSwiadka1}
							</TextFallback>
						</View>
						<View style={{ paddingVertical: '4px' }}>
							<TextFallback fallback="imię i nazwisko świadka 2">
								{props.imieNazwiskoSwiadka2}
							</TextFallback>
							<TextFallback fallback="adres świadka 2">
								{props.adresSwiadka2}
							</TextFallback>
						</View>
					</TableRow>
					<View
						style={{
							fontSize: "10px",
							borderTop: "1px solid #a9013d",
							borderBottom: "1px solid #a9013d",
							display: "flex",
							flexDirection: "row",
						}}
					>
						<View
							style={{
								borderRight: "1px solid #a9013d",
								paddingRight: "4px",
								paddingTop: "4px",
								paddingBottom: "4px",
							}}
						>
							<Text>Nr aktu małżeństwa w miejscowej księdze małżeństw</Text>
						</View>
						<View style={{ paddingTop: "4px", paddingBottom: "4px", paddingLeft: '4px' }}>
							<TextFallback fallback="numer aktu małżeństwa">
								{props.numerAktuMalzenstwa}
							</TextFallback>
						</View>
					</View>
				</View>
				<View style={{ fontSize: '10px', display: 'flex', flexDirection: 'row', width: '100%' }}>
					<Text>Zawiadomienie do parafii chrztu nowożeńców wysłano dnia </Text>
					<TextFallback fallback="data zawiadomienia">
							{printDateInPolish(props.dataWyslaniaZawiadomienia)}
						</TextFallback>
				</View>
				<View style={{ fontSize: '10px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', alignItems: 'center', gap: '32px', position: 'absolute', bottom: '15px' }}>
				<View
						style={{
							height: "50px",
							width: "50px",
							border: "1px dotted #a9013d",
							borderRadius: "50%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Text style={{ fontSize: "8px" }}>(L.S.)</Text>
					</View>
					<View style={{ width: '150px', textAlign: 'center'}}>
						<Text style={{ borderTop: '1px dotted #a9013d'}}>podpis duszpasterza</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

interface FormProps {
	children: React.ReactNode;
	label: string;
}

export const ZawiadomienieOMalzenstwie = (props: FormProps) => {
	const { children, label } = props;
	const formObj = useForm<TemplateFields>({
		defaultValues: {
			dataWystawienia: new Date(),
			miejscowoscDokumentu: "Pawlikowice",
		},
	});
	const { register, control, watch, handleSubmit } = formObj;

	const { onSubmit, ...pdf } = useGenerateDocument({
		watch,
		template: (fields) => <PdfTemplate {...fields} />,
	});

	const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		handleSubmit(() => {
			onSubmit();
		})(e);
	};

	return (
		<form onSubmit={onFormSubmit} className="w-full">
			<FormPdfTemplate
				pdfSection={
					<PdfDocumentContext.Provider value={pdf}>
						{children}
					</PdfDocumentContext.Provider>
				}
				label={label}
			>
				<div className="w-full flex flex-col gap-4 overflow-screen py-12 px-6 overflow-scroll max-h-screen">
						<div className="flex gap-2">
							<TextField
								className="w-full"
								{...register("miejscowoscDokumentu", { required: true })}
								id="outlined-basic"
								label="Miejscowość dokumentu"
								variant="outlined"
							/>
							<Controller
								control={control}
								name="dataWystawienia"
								rules= {{ required: true }}
								render={({ field: { value, ...restField } }) => (
									<DatePicker
										{...restField}
										value={value || null}
										label="Data wystawienia"
										inputFormat="dd-MM-yyyy"
										renderInput={(inputProps) => (
											<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
										)}
									/>
								)}
							/>
						</div>
						<Divider>
						<Chip label="Adresat" />
					</Divider>
					<div className="flex gap-2 items-center">
					<TextField
							className="w-full"
							{...register("parafiaDocelowa")}
							id="outlined-basic"
							label="Parafia"
							variant="outlined"
						/>
						<span className="text-xs">w</span>
						<TextField
							className="w-full"
							{...register("miejscowoscParafiiDocelowej")}
							id="outlined-basic"
							label="Miejscowość"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Licencja" />
					</Divider>
					<div className="flex gap-2">
						<TextField
							className="w-full"
							{...register("numerLicencji")}
							id="outlined-basic"
							label="Numer licencji"
							variant="outlined"
						/>
						<Controller
							control={control}
							name="dataLicencji"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data licencji"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						/>
					</div>
					<Divider>
						<Chip label="Małżonkowie" />
					</Divider>
					<div className="flex gap-2">
						<TextField
							className="w-full"
							{...register("imieNazwiskoMeza")}
							id="outlined-basic"
							label="Imię i nazwisko męża"
							variant="outlined"
						/>
						<TextField
							className="w-full"
							{...register("imieNazwiskoZony")}
							id="outlined-basic"
							label="Imię i nazwisko żony"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Ślub" />
					</Divider>
					<div className="flex gap-2">
						<Controller
							control={control}
							name="dataSlubu"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data ślubu"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						/>
						<TextField
							className="w-full"
							{...register("miejsceSlubu")}
							id="outlined-basic"
							label="Miejsce ślubu"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Asystujący" />
					</Divider>
					<div className="flex gap-2">
						<TextField
							className="w-full"
							{...register("imieNazwiskoAsystujacego")}
							id="outlined-basic"
							label="Imię i nazwisko asystującego"
							variant="outlined"
						/>
						<TextField
							className="w-full"
							{...register("funkcjaAsystentujacego")}
							id="outlined-basic"
							label="Funkcja asystującego"
							variant="outlined"
						/>
					</div>
					<Divider>
						<Chip label="Świadkowie" />
					</Divider>
					<div className="flex gap-2 w-full">
						<div className="flex flex-col w-full gap-4">
							<Divider>
								<Chip label="Świadek 1" />
							</Divider>
							<TextField
								className="w-full"
								{...register("imieNazwiskoSwiadka1")}
								id="outlined-basic"
								label="Imię i nazwisko świadka 1"
								variant="outlined"
							/>
							<TextField
								className="w-full"
								{...register("adresSwiadka1")}
								id="outlined-basic"
								label="Adres świadka 1"
								variant="outlined"
							/>
						</div>
						<div className="flex flex-col w-full gap-4">
							<Divider>
								<Chip label="Świadek 2" />
							</Divider>
							<TextField
								className="w-full"
								{...register("imieNazwiskoSwiadka2")}
								id="outlined-basic"
								label="Imię i nazwisko świadka 2"
								variant="outlined"
							/>
							<TextField
								className="w-full"
								{...register("adresSwiadka2")}
								id="outlined-basic"
								label="Adres świadka 2"
								variant="outlined"
							/>
						</div>
					</div>
					<Divider>
						<Chip label="Pozostałe" />
					</Divider>
					<div className="flex gap-2 w-full">
						<div className="w-full flex flex-col gap-4">
					<Divider>
						<Chip label="Nr aktu małżeństwa" />
					</Divider>
					<TextField
						className="w-full"
						{...register("numerAktuMalzenstwa")}
						id="outlined-basic"
						label="Nr aktu w miejscowej księdze małżeństw"
						variant="outlined"
					/>
					</div>
					<div className="w-full flex flex-col gap-4">
					<Divider>
						<Chip label="Zawiadomienie" />
					</Divider>
					<Controller
							control={control}
							name="dataWyslaniaZawiadomienia"
							render={({ field: { value, ...restField } }) => (
								<DatePicker
									{...restField}
									value={value || null}
									label="Data wysłania zawiadomienia do parafii nowożeńców"
									inputFormat="dd-MM-yyyy"
									renderInput={(inputProps) => (
										<TextField {...inputProps} className="w-full" type="date" inputProps={{ ...inputProps.inputProps,  placeholder: 'dd-mm-rrrr'}} />
									)}
								/>
							)}
						/>
						</div>
						</div>
				</div>
			</FormPdfTemplate>
		</form>
	);
};
