import { Sidebar } from 'components/organisms/sidebar/sidebar';
import { PdfTemplatePageContent } from 'components/organisms/pdf-template-page-content/pdf-template-page-content';
import { MainTemplate } from 'components/templates/main-template';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TemplateFormMap } from 'infrastructure/routes/routes';

const drawerWidth = 200;

export const PdfTemplatePage = () => {
  const { templateName } = useParams();

  return (
    <MainTemplate
      sidebar={<Sidebar drawerWidth={drawerWidth} />}
      mainContent={<PdfTemplatePageContent templateName={templateName as keyof typeof TemplateFormMap} />}
    />
  )
}